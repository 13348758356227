<script setup lang="ts">
import type { RouteLocationRaw } from '#vue-router';
import type { NavigationItem } from '~~/types';

const { globals } = useAppConfig();

const { data: navigation } = await useAsyncData(
  'footerNav',
  () => {
    return useDirectus(
      readItems('navigation', {
        filter: {
          title: {
            _eq: 'Footer',
          },
        },
        fields: [
          {
            items: [
              'id',
              'title',
              'icon',
              'label',
              'type',
              'url',
              'has_children',
              {
                page: ['permalink', 'title'],
                children: [
                  'id',
                  'title',
                  'icon',
                  'label',
                  'type',
                  'url',
                  {
                    page: ['permalink', 'title'],
                  },
                ],
              },
            ],
          },
        ],
      }),
    );
  },
  {
    transform: (data) => data[0],
  },
);

const contactUsUrl = unref('/contact-us');
// const { data: form } = await useAsyncData(
//   'newsletterForm',
//   () => {
//     return useDirectus(
//       readItems('forms', {
//         filter: {
//           key: {
//             _eq: 'newsletter',
//           },
//         },
//       }),
//     );
//   },
//   {
//     transform: (data) => data[0],
//   },
// );
</script>
<template>
  <footer
    class="relative px-8 py-8 bg-white md:px-12 md:py-10 dark:bg-[#000] rounded-panel"
    aria-labelledby="footer-heading"
  >
    <div class="container mx-auto">
      <!-- Header -->
      <div class="flex justify-between items-center space-y-4 md:space-y-0 md:flex-row md:justify-center">
        <!-- Left Column: Logo and Tagline -->
        <div class="flex flex-col items-start text-left md:w-1/2">
          <NuxtLink href="/">
            <Logo class="dark:text-white" />
          </NuxtLink>
          <VText v-if="globals.tagline" class="mt-2 text-black dark:text-white">
            {{ globals.tagline }}
          </VText>
        </div>

        <!-- Right Column: Dark Mode Toggle -->
        <div class="flex items-center justify-end md:w-1/2">
          <DarkModeToggle class="text-black dark:text-white hover:text-gray-400" bg="dark" />
        </div>
      </div>

      <!-- Navigation + Form -->
      <div class="grid gap-8 mt-8 md:grid-cols-2">
        <!-- Left Column: Navigation -->
        <div class="flex flex-col items-start w-full text-left">
          <TypographyHeadline content="<em>Menu</em>" size="sm" />
          <ul role="list" class="flex flex-wrap gap-4 mt-4 md:grid md:grid-cols-2 lg:flex lg:gap-6">
            <li v-for="item in navigation?.items" :key="item.id" class="w-auto md:w-full lg:w-auto">
              <NuxtLink
                :to="getNavItemUrl(item)"
                class="font-medium text-black hover:text-gray-900 dark:text-gray-200 dark:hover:text-primary"
              >
                {{ item.title }}
              </NuxtLink>
            </li>
          </ul>
        </div>

        <!-- Right Column: Contact Us text-right  md:items-end md:text-right sm:items-start sm:text-left xs:items-start xs:text-left-->
        <div
          class="flex flex-col w-full md:items-end md:text-right sm:items-start sm:text-left xs:items-start xs:text-left"
        >
          <TypographyHeadline :content="`<p>Reach out <em>today</em></p>`" size="sm"></TypographyHeadline>
          <UButton :to="contactUsUrl" target="_self" variant="solid" size="xl" class="text-white dark:text-white mt-4">
            Contact Me
          </UButton>
        </div>
      </div>

      <!-- Bottom -->
      <div class="pt-6 mt-8 border-t border-t-black dark:border-t-gray-700 text-center">
        <div class="flex items-center justify-center mt-6 space-x-6">
          <NuxtLink
            v-for="link in globals.social_links"
            :key="link.url"
            :href="link.url"
            class="w-6 h-6 text-white"
            target="_blank"
          >
            <span class="sr-only">{{ link.service }}</span>
            <Icon class="w-8 h-8 text-black dark:text-white hover:opacity-75" :name="`mdi:${link.service}`" />
          </NuxtLink>
        </div>
        <div class="mt-8">
          <span class="text-black dark:text-gray-400">
            Copyright © 1988 - {{ new Date().getFullYear() }}
            <NuxtLink href="/" class="mx-2 hover:text-primary" rel="noopener noreferrer">
              {{ globals.title }}
            </NuxtLink>
            All rights reserved.
          </span>
          <span class="block mt-2 text-black dark:text-gray-400">
            <Icon name="heroicons:bolt" class="w-4 h-4 text-primary" />
            Site crafted by
            <NuxtLink
              href="https://ridiculousengineering.com"
              target="_blank"
              rel="noopener noreferrer"
              class="border-b dark:border-b-gray-700 hover:text-primary"
              aria-label="Visit the Ridiculous Engineering LLC website in a new tab"
            >
              Ridiculous Engineering LLC
            </NuxtLink>
            utilizing
            <NuxtLink
              href="https://www.directus.io"
              target="_blank"
              rel="noopener noreferrer"
              class="border-b dark:border-b-gray-700 hover:text-primary"
              aria-label="Visit the Directus website in a new tab"
            >
              Directus
            </NuxtLink>
            and
            <NuxtLink
              href="https://www.nuxt.com?ref=ridiculousengineering_directus_footer"
              target="_blank"
              rel="noopener noreferrer"
              class="border-b dark:border-b-gray-700 hover:text-primary"
              aria-label="Visit the Nuxt website in a new tab, referred by ridiculousengineering_directus_footer"
            >
              Nuxt
            </NuxtLink>
            .
          </span>
        </div>
      </div>
    </div>
  </footer>
</template>
