import type { RestCommand, RestClient } from '@directus/sdk';
import { withSearch } from '@directus/sdk';
import type { Schema } from '~/types';
import { useNuxtApp } from '#imports';


export default async function useDirectus<Output extends object>(
  //@ts-ignore
  options: RestCommand<Output, DirectusSchema>,
): Promise<Output> {
  const nuxtApp = useNuxtApp();
  const $directus = nuxtApp.$directus as RestClient<Schema>;
  const runtimeConfig = useRuntimeConfig();
  const cuidValue = runtimeConfig.public.directus.rest.zorba;
  const nuxtEnv = runtimeConfig.public.nuxtEnv;
  const nodeEnv = runtimeConfig.public.nodeEnv;
  let envValue;

  const optValue = options();

  if (!optValue.params) {
    optValue.params = {};
  }

  if (nodeEnv === 'development') {
    envValue = 'dev';
  } else if (nuxtEnv === 'staging') {
    envValue = 'stage';
  } else {
    envValue = 'prod';
  }
  let paramRoute = '';
 if(optValue?.params?.filter){
  //get the following values from the filter object and assign them to the params object filter.slug._eq, filter.title._eq, filter.folder._eq, filter.permalink._eq
  if(optValue.params.filter?.slug){
    paramRoute = optValue.params.filter.slug._eq
  }else if(optValue.params.filter?.title){
    paramRoute = optValue.params.filter.title._eq
  }else if(optValue.params.filter?.folder){
    paramRoute = optValue.params.filter.folder._eq
  }else if(optValue.params.filter?.permalink){
    paramRoute = optValue.params.filter.permalink._eq
  }
}
  //console.log('paramRoute', paramRoute);
  optValue.params.cuid = cuidValue;
  optValue.params.ne = envValue;

  // optValue.headers = {
  //   "Access-Control-Allow-Origin":
  //     runtimeConfig.public.directus.rest.nuxtBaseUrl,
  // };
  
  //console.log('customHeaderData', cuidValue+'/'+envValue+optValue.path+'/'+paramRoute);
  // Set customHeaderData property for the plugin to use
  //optValue.customHeaderData = cuidValue+envValue+optValue.path+paramRoute;
  
  // Remove direct header assignment if exists
  // ...existing code...
   
  //console.log('optValue', JSON.stringify(optValue, null, 2));
  //console.log('optionsfunc', JSON.stringify(optValue,null,2));
  if (runtimeConfig.public.directus.rest.logLevel === 'trace') {
    console.log('envValue', envValue);
    console.log('cuidValue', cuidValue);
    console.log('useDirectus', JSON.stringify(optValue.params.filter, null, 2));
    console.log('$directus ', JSON.stringify($directus, null, 2));
    console.log('nuxtEnv', nuxtEnv);
  }

  const computedCDNTag = cuidValue+envValue+optValue.path+paramRoute;
  //console.log('CDN-Tag to be set', computedCDNTag);
  
  // Store the computed header value in a shared state
  const directusCustomHeader = useState<string>('directusCustomHeader');
  directusCustomHeader.value = computedCDNTag;

  return await $directus.request<Output>(options);
}
