import revive_payload_client_m2bEOStd3U from "/Users/patrick/Code/bvb_contractors/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.0_@types+node@22.10.5_db0@0.2.1_eslint@8.57.0_ioredis@5.4.2_m_plbicrashz67ebpojssovyua4m/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_V7SVEiq6DA from "/Users/patrick/Code/bvb_contractors/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.0_@types+node@22.10.5_db0@0.2.1_eslint@8.57.0_ioredis@5.4.2_m_plbicrashz67ebpojssovyua4m/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_OgjciM7ffG from "/Users/patrick/Code/bvb_contractors/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.0_@types+node@22.10.5_db0@0.2.1_eslint@8.57.0_ioredis@5.4.2_m_plbicrashz67ebpojssovyua4m/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_XniM82Oax6 from "/Users/patrick/Code/bvb_contractors/node_modules/.pnpm/nuxt-site-config@2.2.21_magicast@0.3.5_rollup@4.30.1_vite@6.2.3_@types+node@22.10.5_jiti@2.4._gtrhtsbnx2ntojtxv6ahqg772a/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_p71N5HoN7d from "/Users/patrick/Code/bvb_contractors/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.0_@types+node@22.10.5_db0@0.2.1_eslint@8.57.0_ioredis@5.4.2_m_plbicrashz67ebpojssovyua4m/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_pzGkD58aPZ from "/Users/patrick/Code/bvb_contractors/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.0_@types+node@22.10.5_db0@0.2.1_eslint@8.57.0_ioredis@5.4.2_m_plbicrashz67ebpojssovyua4m/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_O8zEbJQnXQ from "/Users/patrick/Code/bvb_contractors/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.0_@types+node@22.10.5_db0@0.2.1_eslint@8.57.0_ioredis@5.4.2_m_plbicrashz67ebpojssovyua4m/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_ugx1l8C74i from "/Users/patrick/Code/bvb_contractors/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.0_@types+node@22.10.5_db0@0.2.1_eslint@8.57.0_ioredis@5.4.2_m_plbicrashz67ebpojssovyua4m/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/Users/patrick/Code/bvb_contractors/.nuxt/components.plugin.mjs";
import prefetch_client_D8rD1UWM3t from "/Users/patrick/Code/bvb_contractors/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.0_@types+node@22.10.5_db0@0.2.1_eslint@8.57.0_ioredis@5.4.2_m_plbicrashz67ebpojssovyua4m/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_Y0ytzr07SI from "/Users/patrick/Code/bvb_contractors/node_modules/.pnpm/@nuxtjs+plausible@1.2.0_magicast@0.3.5_rollup@4.30.1/node_modules/@nuxtjs/plausible/dist/runtime/plugin.client.js";
import motion_dAptULdsO7 from "/Users/patrick/Code/bvb_contractors/node_modules/.pnpm/@vueuse+motion@2.2.6_magicast@0.3.5_rollup@4.30.1_vue@3.5.13_typescript@5.6.3_/node_modules/@vueuse/motion/dist/nuxt/runtime/templates/motion.js";
import slideovers_aeGWQoFFmO from "/Users/patrick/Code/bvb_contractors/node_modules/.pnpm/@nuxt+ui@2.19.2_change-case@5.4.4_jwt-decode@4.0.0_magicast@0.3.5_rollup@4.30.1_vite@6.2.3_@t_xv53uq2xj3yg7r2nzp3s5ogu7e/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.js";
import modals_kSfxOzJLv7 from "/Users/patrick/Code/bvb_contractors/node_modules/.pnpm/@nuxt+ui@2.19.2_change-case@5.4.4_jwt-decode@4.0.0_magicast@0.3.5_rollup@4.30.1_vite@6.2.3_@t_xv53uq2xj3yg7r2nzp3s5ogu7e/node_modules/@nuxt/ui/dist/runtime/plugins/modals.js";
import colors_ABRGCTyPbq from "/Users/patrick/Code/bvb_contractors/node_modules/.pnpm/@nuxt+ui@2.19.2_change-case@5.4.4_jwt-decode@4.0.0_magicast@0.3.5_rollup@4.30.1_vite@6.2.3_@t_xv53uq2xj3yg7r2nzp3s5ogu7e/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_CS8Nw8Hh9h from "/Users/patrick/Code/bvb_contractors/node_modules/.pnpm/@nuxtjs+color-mode@3.5.2_magicast@0.3.5_rollup@4.30.1/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_LFArrRj64O from "/Users/patrick/Code/bvb_contractors/node_modules/.pnpm/@nuxt+icon@1.10.3_magicast@0.3.5_rollup@4.30.1_vite@6.2.3_@types+node@22.10.5_jiti@2.4.2_ters_gozn24um7y6u4vxvy6mifi363a/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import scrollbars_client_j1qoCqgJXj from "/Users/patrick/Code/bvb_contractors/node_modules/.pnpm/@nuxt+ui-pro@1.6.0_change-case@5.4.4_jwt-decode@4.0.0_magicast@0.3.5_rollup@4.30.1_typescript_qdax4vupwxnekqpnsgxlxfidpe/node_modules/@nuxt/ui-pro/modules/pro/runtime/plugins/scrollbars.client.ts";
import directus_MCKsK3zbCM from "/Users/patrick/Code/bvb_contractors/modules/directus/runtime/plugins/directus.ts";
import auth_l3hBn8iHZ2 from "/Users/patrick/Code/bvb_contractors/modules/directus/runtime/plugins/auth.ts";
import presets_bAB3empuuf from "/Users/patrick/Code/bvb_contractors/node_modules/.pnpm/@nuxt+ui-pro@1.6.0_change-case@5.4.4_jwt-decode@4.0.0_magicast@0.3.5_rollup@4.30.1_typescript_qdax4vupwxnekqpnsgxlxfidpe/node_modules/@nuxt/ui-pro/plugins/presets.ts";
import variables_1PIyQNhs1c from "/Users/patrick/Code/bvb_contractors/node_modules/.pnpm/@nuxt+ui-pro@1.6.0_change-case@5.4.4_jwt-decode@4.0.0_magicast@0.3.5_rollup@4.30.1_typescript_qdax4vupwxnekqpnsgxlxfidpe/node_modules/@nuxt/ui-pro/plugins/variables.ts";
import animate_WjYTuMT2ye from "/Users/patrick/Code/bvb_contractors/plugins/animate.ts";
import plugin_auto_pageviews_client_HCGALhu8yl from "/Users/patrick/Code/bvb_contractors/node_modules/.pnpm/@nuxtjs+plausible@1.2.0_magicast@0.3.5_rollup@4.30.1/node_modules/@nuxtjs/plausible/dist/runtime/plugin-auto-pageviews.client.js";
export default [
  revive_payload_client_m2bEOStd3U,
  unhead_V7SVEiq6DA,
  router_OgjciM7ffG,
  _0_siteConfig_XniM82Oax6,
  payload_client_p71N5HoN7d,
  navigation_repaint_client_pzGkD58aPZ,
  check_outdated_build_client_O8zEbJQnXQ,
  chunk_reload_client_ugx1l8C74i,
  components_plugin_KR1HBZs4kY,
  prefetch_client_D8rD1UWM3t,
  plugin_client_Y0ytzr07SI,
  motion_dAptULdsO7,
  slideovers_aeGWQoFFmO,
  modals_kSfxOzJLv7,
  colors_ABRGCTyPbq,
  plugin_client_CS8Nw8Hh9h,
  plugin_LFArrRj64O,
  scrollbars_client_j1qoCqgJXj,
  directus_MCKsK3zbCM,
  auth_l3hBn8iHZ2,
  presets_bAB3empuuf,
  variables_1PIyQNhs1c,
  animate_WjYTuMT2ye,
  plugin_auto_pageviews_client_HCGALhu8yl
]