<script setup lang="ts">
const {
  theme,
  globals: { title },
} = useAppConfig();

const config = useRuntimeConfig();

const {
  data: navigation,
  pending,
  error,
} = await useAsyncData(
  'mainNavigation',
  () => {
    return useDirectus(
      readItems('navigation', {
        filter: {
          title: {
            _eq: 'Main',
          },
        },
        fields: [
          {
            items: [
              'id',
              'has_children',
              'title',
              'icon',
              'label',
              'type',
              'url',
              {
                page: ['permalink', 'title'],
                children: [
                  'id',
                  'title',
                  'has_children',
                  'icon',
                  'label',
                  'type',
                  'url',
                  {
                    page: ['permalink', 'title'],
                  },
                ],
              },
            ],
          },
        ],
      }),
    );
  },
  {
    transform: (data) => data[0],
  },
);
</script>
<template>
  <header class="relative w-full mx-auto space-y-4 md:flex md:items-center md:space-y-0 md:gap-x-4">
    <div class="flex items-center bg-white dark:bg-midnight-blue-900 justify-between py-2 px-6 md:flex-1 shadow-md">
      <NuxtLink :href="config.public.directus.rest.nuxtBaseUrl" class="py-2">
        <Logo class="text-white" />
        <span class="sr-only">{{ title }}</span>
      </NuxtLink>

      <nav class="hidden md:flex md:space-x-4 lg:space-x-6" aria-label="Global">
        <!-- <div v-for="item in navigation?.items" :key="item.id" :item="item">{{ item }}<br/></div> -->
        <NavigationMenuItem v-for="item in navigation?.items" :key="item.id" :item="item" />
      </nav>
      <div class="flex items-center justify-end flex-shrink-0 space-x-2">
        <DarkModeToggle class="hidden text-midnight-blue-500 dark:text-white md:block hover:text-gray-400" bg="dark" />
      </div>
    </div>

    <!-- <div class="hidden h-full gap-4 md:flex">
      <UButton to="/contact-us" color="primary" size="xl" class="px-2">Let's Talk</UButton>
      <UButton to="/portal" color="primary" variant="ghost" size="xl" class="px-2">Login</UButton>
    </div> -->
    <NavigationMobileMenu v-if="navigation" :navigation="navigation" />
  </header>
</template>
