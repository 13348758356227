
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as _91_46_46_46permalink_93GOuGnU5nhwMeta } from "/Users/patrick/Code/bvb_contractors/pages/[...permalink].vue?macro=true";
import { default as logout2Ykjx0kAR8Meta } from "/Users/patrick/Code/bvb_contractors/pages/auth/logout.vue?macro=true";
import { default as signinTu8huRQBuWMeta } from "/Users/patrick/Code/bvb_contractors/pages/auth/signin.vue?macro=true";
import { default as authEfyAp5ghlrMeta } from "/Users/patrick/Code/bvb_contractors/layers/portal/pages/auth.vue?macro=true";
import { default as _91slug_93ZTKjHEgd4jMeta } from "/Users/patrick/Code/bvb_contractors/pages/help/articles/[slug].vue?macro=true";
import { default as _91slug_937eIKHXS2ZAMeta } from "/Users/patrick/Code/bvb_contractors/pages/help/collections/[slug].vue?macro=true";
import { default as indexF9e3zLzej1Meta } from "/Users/patrick/Code/bvb_contractors/pages/help/index.vue?macro=true";
import { default as indexEsQoPuyyP1Meta } from "/Users/patrick/Code/bvb_contractors/layers/portal/pages/portal/account/index.vue?macro=true";
import { default as indexMG6cgFQQ3RMeta } from "/Users/patrick/Code/bvb_contractors/layers/portal/pages/portal/billing/index.vue?macro=true";
import { default as _91id_934GSP573gXnMeta } from "/Users/patrick/Code/bvb_contractors/layers/portal/pages/portal/billing/invoices/[id].vue?macro=true";
import { default as indexbF2I8ps767Meta } from "/Users/patrick/Code/bvb_contractors/layers/portal/pages/portal/billing/invoices/index.vue?macro=true";
import { default as _91id_93oO3amjpMeqMeta } from "/Users/patrick/Code/bvb_contractors/layers/portal/pages/portal/files/folders/[id].vue?macro=true";
import { default as indexa3OqYN90nfMeta } from "/Users/patrick/Code/bvb_contractors/layers/portal/pages/portal/files/index.vue?macro=true";
import { default as _91slug_93xeGrGHjxDQMeta } from "/Users/patrick/Code/bvb_contractors/layers/portal/pages/portal/help/articles/[slug].vue?macro=true";
import { default as _91slug_93WVQuuABMd1Meta } from "/Users/patrick/Code/bvb_contractors/layers/portal/pages/portal/help/collections/[slug].vue?macro=true";
import { default as indexRxQpXmi2GNMeta } from "/Users/patrick/Code/bvb_contractors/layers/portal/pages/portal/help/index.vue?macro=true";
import { default as indext7IDsKVO02Meta } from "/Users/patrick/Code/bvb_contractors/layers/portal/pages/portal/index.vue?macro=true";
import { default as billingzNzsZsCPSVMeta } from "/Users/patrick/Code/bvb_contractors/layers/portal/pages/portal/projects/[id]/billing.vue?macro=true";
import { default as conversationsph3DD0KubHMeta } from "/Users/patrick/Code/bvb_contractors/layers/portal/pages/portal/projects/[id]/conversations.vue?macro=true";
import { default as filesh19qjuAtupMeta } from "/Users/patrick/Code/bvb_contractors/layers/portal/pages/portal/projects/[id]/files.vue?macro=true";
import { default as indexJIwqUKeXRcMeta } from "/Users/patrick/Code/bvb_contractors/layers/portal/pages/portal/projects/[id]/index.vue?macro=true";
import { default as taskslx03zJufueMeta } from "/Users/patrick/Code/bvb_contractors/layers/portal/pages/portal/projects/[id]/tasks.vue?macro=true";
import { default as _91id_93Ow6MGXc0ylMeta } from "/Users/patrick/Code/bvb_contractors/layers/portal/pages/portal/projects/[id].vue?macro=true";
import { default as indexzkassGAGYfMeta } from "/Users/patrick/Code/bvb_contractors/layers/portal/pages/portal/projects/index.vue?macro=true";
import { default as portalia75BkKsB7Meta } from "/Users/patrick/Code/bvb_contractors/layers/portal/pages/portal.vue?macro=true";
import { default as _91slug_93wDja7GmbokMeta } from "/Users/patrick/Code/bvb_contractors/pages/posts/[slug].vue?macro=true";
import { default as _91category_93RrxEQT3szrMeta } from "/Users/patrick/Code/bvb_contractors/pages/posts/categories/[category].vue?macro=true";
import { default as indexVfZpy1yXjfMeta } from "/Users/patrick/Code/bvb_contractors/pages/posts/index.vue?macro=true";
import { default as projectsFokqORiGBwMeta } from "/Users/patrick/Code/bvb_contractors/pages/projects.vue?macro=true";
import { default as _91id_93kTpZu1lDA1Meta } from "/Users/patrick/Code/bvb_contractors/layers/proposals/pages/proposals/[id].vue?macro=true";
import { default as component_45stub2Yxr500o6vMeta } from "/Users/patrick/Code/bvb_contractors/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.0_@types+node@22.10.5_db0@0.2.1_eslint@8.57.0_ioredis@5.4.2_m_plbicrashz67ebpojssovyua4m/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stub2Yxr500o6v } from "/Users/patrick/Code/bvb_contractors/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.0_@types+node@22.10.5_db0@0.2.1_eslint@8.57.0_ioredis@5.4.2_m_plbicrashz67ebpojssovyua4m/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "permalink",
    path: "/:permalink(.*)*",
    component: () => import("/Users/patrick/Code/bvb_contractors/pages/[...permalink].vue")
  },
  {
    name: "auth",
    path: "/auth",
    meta: authEfyAp5ghlrMeta || {},
    component: () => import("/Users/patrick/Code/bvb_contractors/layers/portal/pages/auth.vue"),
    children: [
  {
    name: "auth-logout",
    path: "logout",
    component: () => import("/Users/patrick/Code/bvb_contractors/pages/auth/logout.vue")
  },
  {
    name: "auth-signin",
    path: "signin",
    component: () => import("/Users/patrick/Code/bvb_contractors/pages/auth/signin.vue")
  }
]
  },
  {
    name: "help-articles-slug",
    path: "/help/articles/:slug()",
    component: () => import("/Users/patrick/Code/bvb_contractors/pages/help/articles/[slug].vue")
  },
  {
    name: "help-collections-slug",
    path: "/help/collections/:slug()",
    component: () => import("/Users/patrick/Code/bvb_contractors/pages/help/collections/[slug].vue")
  },
  {
    name: "help",
    path: "/help",
    component: () => import("/Users/patrick/Code/bvb_contractors/pages/help/index.vue")
  },
  {
    name: portalia75BkKsB7Meta?.name,
    path: "/portal",
    meta: portalia75BkKsB7Meta || {},
    component: () => import("/Users/patrick/Code/bvb_contractors/layers/portal/pages/portal.vue"),
    children: [
  {
    name: "portal-account",
    path: "account",
    component: () => import("/Users/patrick/Code/bvb_contractors/layers/portal/pages/portal/account/index.vue")
  },
  {
    name: "portal-billing",
    path: "billing",
    meta: indexMG6cgFQQ3RMeta || {},
    redirect: "/portal/billing/invoices",
    component: () => import("/Users/patrick/Code/bvb_contractors/layers/portal/pages/portal/billing/index.vue")
  },
  {
    name: "portal-billing-invoices-id",
    path: "billing/invoices/:id()",
    component: () => import("/Users/patrick/Code/bvb_contractors/layers/portal/pages/portal/billing/invoices/[id].vue")
  },
  {
    name: "portal-billing-invoices",
    path: "billing/invoices",
    component: () => import("/Users/patrick/Code/bvb_contractors/layers/portal/pages/portal/billing/invoices/index.vue")
  },
  {
    name: "portal-files-folders-id",
    path: "files/folders/:id()",
    component: () => import("/Users/patrick/Code/bvb_contractors/layers/portal/pages/portal/files/folders/[id].vue")
  },
  {
    name: "portal-files",
    path: "files",
    component: () => import("/Users/patrick/Code/bvb_contractors/layers/portal/pages/portal/files/index.vue")
  },
  {
    name: "portal-help-articles-slug",
    path: "help/articles/:slug()",
    component: () => import("/Users/patrick/Code/bvb_contractors/layers/portal/pages/portal/help/articles/[slug].vue")
  },
  {
    name: "portal-help-collections-slug",
    path: "help/collections/:slug()",
    component: () => import("/Users/patrick/Code/bvb_contractors/layers/portal/pages/portal/help/collections/[slug].vue")
  },
  {
    name: "portal-help",
    path: "help",
    component: () => import("/Users/patrick/Code/bvb_contractors/layers/portal/pages/portal/help/index.vue")
  },
  {
    name: "portal",
    path: "",
    component: () => import("/Users/patrick/Code/bvb_contractors/layers/portal/pages/portal/index.vue")
  },
  {
    name: _91id_93Ow6MGXc0ylMeta?.name,
    path: "projects/:id()",
    component: () => import("/Users/patrick/Code/bvb_contractors/layers/portal/pages/portal/projects/[id].vue"),
    children: [
  {
    name: "portal-projects-id-billing",
    path: "billing",
    component: () => import("/Users/patrick/Code/bvb_contractors/layers/portal/pages/portal/projects/[id]/billing.vue")
  },
  {
    name: "portal-projects-id-conversations",
    path: "conversations",
    component: () => import("/Users/patrick/Code/bvb_contractors/layers/portal/pages/portal/projects/[id]/conversations.vue")
  },
  {
    name: "portal-projects-id-files",
    path: "files",
    component: () => import("/Users/patrick/Code/bvb_contractors/layers/portal/pages/portal/projects/[id]/files.vue")
  },
  {
    name: "portal-projects-id",
    path: "",
    component: () => import("/Users/patrick/Code/bvb_contractors/layers/portal/pages/portal/projects/[id]/index.vue")
  },
  {
    name: "portal-projects-id-tasks",
    path: "tasks",
    component: () => import("/Users/patrick/Code/bvb_contractors/layers/portal/pages/portal/projects/[id]/tasks.vue")
  }
]
  },
  {
    name: "portal-projects",
    path: "projects",
    component: () => import("/Users/patrick/Code/bvb_contractors/layers/portal/pages/portal/projects/index.vue")
  }
]
  },
  {
    name: "posts-slug",
    path: "/posts/:slug()",
    component: () => import("/Users/patrick/Code/bvb_contractors/pages/posts/[slug].vue")
  },
  {
    name: "posts-categories-category",
    path: "/posts/categories/:category()",
    component: () => import("/Users/patrick/Code/bvb_contractors/pages/posts/categories/[category].vue")
  },
  {
    name: "posts",
    path: "/posts",
    component: () => import("/Users/patrick/Code/bvb_contractors/pages/posts/index.vue")
  },
  {
    name: "projects",
    path: "/projects",
    component: () => import("/Users/patrick/Code/bvb_contractors/pages/projects.vue")
  },
  {
    name: "proposals-id",
    path: "/proposals/:id()",
    meta: _91id_93kTpZu1lDA1Meta || {},
    component: () => import("/Users/patrick/Code/bvb_contractors/layers/proposals/pages/proposals/[id].vue")
  },
  {
    name: component_45stub2Yxr500o6vMeta?.name,
    path: "/portfolio",
    component: component_45stub2Yxr500o6v
  },
  {
    name: component_45stub2Yxr500o6vMeta?.name,
    path: "/about-us",
    component: component_45stub2Yxr500o6v
  },
  {
    name: component_45stub2Yxr500o6vMeta?.name,
    path: "/contact",
    component: component_45stub2Yxr500o6v
  },
  {
    name: component_45stub2Yxr500o6vMeta?.name,
    path: "/sitemap.xml",
    component: component_45stub2Yxr500o6v
  },
  {
    name: component_45stub2Yxr500o6vMeta?.name,
    path: "/0-sitemap.xml",
    component: component_45stub2Yxr500o6v
  },
  {
    name: component_45stub2Yxr500o6vMeta?.name,
    path: "/1-sitemap.xml",
    component: component_45stub2Yxr500o6v
  },
  {
    name: component_45stub2Yxr500o6vMeta?.name,
    path: "/2-sitemap.xml",
    component: component_45stub2Yxr500o6v
  }
]