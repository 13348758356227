import { createDirectus, rest, authentication, staticToken } from '@directus/sdk';
import type { AuthenticationClient, RestClient, AuthenticationStorage, AuthenticationData } from '@directus/sdk';
import type { Schema } from '~/types/schema';

import {
  defineNuxtPlugin,
  addRouteMiddleware,
  useRuntimeConfig,
  useState,
  useDirectusAuth,
  useRoute,
  useNuxtApp,
} from '#imports';

export default defineNuxtPlugin((nuxtApp) => {
  const route = useRoute();
  const config = useRuntimeConfig();
  const directusUrl = config.public.directus.rest.baseUrl as string;

  const { isTokenExpired } = useDirectusAuth();

  const envToken = config.public.directus.rest.zorba as string;

  // We're creating a custom storage class to use the Nuxt so we can use auth on the server and clien
  class CookieStorage {
    get() {
      const cookie = useCookie('directus-auth');
      return cookie.value;
    }

    set(value: AuthenticationData) {
      const cookie = useCookie('directus-auth');
      cookie.value = value as any;
    }
  }

  const directus: RestClient<Schema> & AuthenticationClient<Schema> = createDirectus<Schema>(directusUrl, {
    globals: {
      fetch: $fetch, // We're using the built-in Nuxt $fetch from ofetch
    },
  })
    .with(
      authentication('json', {
        storage: new CookieStorage() as AuthenticationStorage,
        credentials: 'include',
      }),
    )
    .with(staticToken(envToken))
    .with(
      rest({
        onRequest: async (request) => {
          const userToken = await directus.getToken();
          
          if (request.method === 'GET') {
            // Retrieve the shared header value
            const directusCustomHeader = useState<string>('directusCustomHeader');
            request.headers = {
              ...request.headers,
              'Access-Control-Allow-Methods': 'GET,HEAD,PUT,PATCH,POST,DELETE',
              'Access-Control-Allow-Origin': config.public.directus.rest.nuxtBaseUrl,
              'Access-Control-Allow-Credentials': 'true',
              'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept, Authorization',
              // Add header if present
              ...(directusCustomHeader.value ? { 'CDN-Tag': directusCustomHeader.value } : {})
            };
            // Clear the shared header after using it
            //console.log("directusCustomHeader.value", directusCustomHeader.value);
            //directusCustomHeader.value = undefined;
          }

          if (config.public.directus.rest.logLevel === 'trace') {
            console.log('route: ', JSON.stringify(route, null, 2));
            console.info('directusUrl: ', directusUrl);
            console.log('request: ', request);
          }

          return request;
        },
      }),
    );
  // directus.

  // ** Live Preview Bits **
  // Check if we are in preview mode
  const preview = route.query.preview && route.query.preview === 'true';
  const token = route.query.token as string | undefined;

  // If we are in preview mode, we need to use the token from the query string
  if (preview && token) {
    directus.setToken(token);

    nuxtApp.hook('page:finish', () => {
      refreshNuxtData();
    });
  }

  return {
    provide: {
      directus,
    },
  };
});
