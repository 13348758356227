<script setup lang="ts">
import { borderRadiusMap } from '~/theme';

const { globals, theme } = useAppConfig();
const { fileUrl } = useFiles();

// JSON-LD
useSchemaOrg([
  defineOrganization({
    name: globals?.title ?? 'BVB General Contractors',
    logo: globals?.logo_on_light_bg ? fileUrl(globals?.logo_on_light_bg) : '/logos/agencyos.png',
    sameAs: () => {
      const socialLinks = globals?.social_links ?? [];
      return socialLinks.map((link) => link.url);
    },
  }),
]);

useHead({
  style: [
    {
      id: 'border-radius',
      innerHTML: `:root {${Object.entries(borderRadiusMap[theme.borderRadius])
        .map(([key, value]) => `--border-radius-${key}: ${value};`)
        .join('\n')}\n${Object.entries(theme.fonts)
        .map(([key, value]) => `--font-${key}: ${value};`)
        .join('\n')}`,
    },
  ],
  htmlAttrs: {
    lang: "en",
  },
});
</script>
<template>
  <NuxtLayout>
    <NuxtLoadingIndicator
      color="repeating-linear-gradient(to right,#7f7f7f
    0%,#972C08 100%)"
    />
    <NuxtPage class="w-full"/>
  </NuxtLayout>
</template>
