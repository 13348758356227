import routerOptions0 from "/Users/patrick/Code/bvb_contractors/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.0_@types+node@22.10.5_db0@0.2.1_eslint@8.57.0_ioredis@5.4.2_m_plbicrashz67ebpojssovyua4m/node_modules/nuxt/dist/pages/runtime/router.options.js";
import routerOptions1 from "/Users/patrick/Code/bvb_contractors/node_modules/.pnpm/@nuxt+ui-pro@1.6.0_change-case@5.4.4_jwt-decode@4.0.0_magicast@0.3.5_rollup@4.30.1_typescript_qdax4vupwxnekqpnsgxlxfidpe/node_modules/@nuxt/ui-pro/modules/pro/runtime/app/router.options.ts";
const configRouterOptions = {
  hashMode: false,
  scrollBehaviorType: "auto"
}
export const hashMode = routerOptions0.hashMode ?? false
export default {
...configRouterOptions,
...routerOptions0,
...routerOptions1,
}