<script setup lang="ts">
import type { ParsedContent } from '@nuxt/content';
import type { NuxtError } from '#app';
import { borderRadiusMap } from '~/theme';

const { globals, theme } = useAppConfig();

useSeoMeta({
  title: 'Page not found',
  description: 'We are sorry but this page could not be found.',
});

defineProps({
  error: {
    type: Object as PropType<NuxtError>,
    required: true,
  },
});

useHead({
  htmlAttrs: {
    lang: 'en',
  },
  style: [
    {
      id: 'border-radius',
      innerHTML: `:root {${Object.entries(borderRadiusMap[theme.borderRadius])
        .map(([key, value]) => `--border-radius-${key}: ${value};`)
        .join('\n')}\n${Object.entries(theme.fonts)
        .map(([key, value]) => `--font-${key}: ${value};`)
        .join('\n')}`,
    },
  ],
});

const { data: navigation } = await useAsyncData('navigation', () => fetchContentNavigation(), { default: () => [] });
//const { data: files } = useLazyFetch<ParsedContent[]>('/api/search.json', { default: () => [], server: false });

provide('navigation', navigation);

const errorMessages: { [key: number]: string } = {
  100: 'Continue: You are on the right track, keep going!',
  101: 'Switching Protocols: Changing lanes, please wait.',
  200: 'OK: Everything is awesome!',
  201: 'Created: Your request has been granted, new resource created.',
  202: 'Accepted: We got your request, working on it!',
  203: "Non-Authoritative Information: This is what we have, but don't quote us on it.",
  204: 'No Content: Nothing to see here, move along.',
  205: 'Reset Content: Please refresh for more excitement.',
  206: "Partial Content: Here's a piece of what you wanted.",
  300: 'Multiple Choices: Too many options, choose wisely.',
  301: "Moved Permanently: We've shifted things around.",
  302: 'Found: We found it, just not here.',
  303: 'See Other: Check this out instead.',
  304: 'Not Modified: Same old, same old.',
  305: 'Use Proxy: Take a detour through the proxy.',
  307: 'Temporary Redirect: Take a temporary detour.',
  308: 'Permanent Redirect: Take this new permanent route.',
  400: "Bad Request: Something's off with your request.",
  401: 'Unauthorized: You shall not pass!',
  402: 'Payment Required: Pay up to proceed.',
  403: "Forbidden: Nope, you can't access this.",
  404: 'Not Found: The page you are looking for does not exist.',
  405: "Method Not Allowed: That method isn't supported here.",
  406: "Not Acceptable: We can't give you what you want.",
  407: 'Proxy Authentication Required: Authenticate with the proxy first.',
  408: 'Request Timeout: You took too long, try again.',
  409: "Conflict: There's a conflict with your request.",
  410: "Gone: It was here, now it's not.",
  411: 'Length Required: Specify the length to proceed.',
  412: "Precondition Failed: Your precondition didn't match.",
  413: "Payload Too Large: Whoa, that's too much data.",
  414: 'URI Too Long: That URL is too lengthy.',
  415: "Unsupported Media Type: We don't support that format.",
  416: "Range Not Satisfiable: Can't fulfill that range request.",
  417: "Expectation Failed: We couldn't meet your expectations.",
  418: "I'm a Teapot: Yes, you read that right.",
  421: "Misdirected Request: You've got the wrong server.",
  422: "Unprocessable Entity: We can't process this.",
  423: 'Locked: This resource is locked down.',
  424: 'Failed Dependency: A required dependency failed.',
  425: "Too Early: Hold your horses, it's too soon.",
  426: 'Upgrade Required: Time for an upgrade!',
  428: 'Precondition Required: Precondition is missing.',
  429: "Too Many Requests: Slow down, you're making too many requests.",
  431: 'Request Header Fields Too Large: Your headers are too big.',
  451: 'Unavailable For Legal Reasons: Legal restrictions apply.',
  500: 'Internal Server Error: An unexpected error occurred.',
  501: "Not Implemented: This feature isn't available yet.",
  502: "Bad Gateway: There's trouble at the gateway.",
  503: 'Service Unavailable: Service is taking a break.',
  504: 'Gateway Timeout: The gateway took too long.',
  505: 'HTTP Version Not Supported: Unsupported HTTP version.',
  506: 'Variant Also Negotiates: Configuration error.',
  507: 'Insufficient Storage: Not enough storage space.',
  508: 'Loop Detected: Infinite loop detected.',
  510: 'Not Extended: Further extensions required.',
  511: 'Network Authentication Required: Network authentication needed.',
  999: 'This error needs therapy, we cannot even describe it.',
};

//modified min-h calc to 75 instead of 100 so it would not push the footer below viewport
const errorUI = {
  wrapper: 'min-h-[calc(75vh-var(--header-height))] flex flex-col items-center justify-center',
  status: 'text-base font-semibold text-primary',
  name: 'text-3xl font-bold tracking-tight text-black dark:text-white sm:text-5xl',
  message: 'mt-6 text-base/7 text-black dark:text-gray-400 text-center',
  links: 'mt-10 flex items-center justify-center gap-x-6',
  default: {
    clearButton: {
      label: 'Go back home',
      color: 'primary',
      size: 'lg',
      class: 'text-white dark:text-white',
    },
  },
};
</script>

<template>
  <div>
    <!-- <UMain>
			<UContainer>
				<UPage> -->
    <div class="relative w-full  px-0 pt-0 mx-auto lg:px-0">
      <NavigationTheHeader />
    </div>
    <UPageError
      :ui="errorUI"
      :status="error.statusCode"
      name="Oops! Something went wrong…"
      :message="errorMessages[error.statusCode] ? errorMessages[error.statusCode] : errorMessages[999]"
    />
    <!-- </UPage> -->
    <div class="w-full px-0 pt-6 pb-12 mx-auto lg:px-0">
      <NavigationTheFooter />
    </div>
    <!-- </UContainer>
		</UMain> -->
  </div>
</template>
